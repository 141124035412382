










































import { Component, Mixins, Prop } from 'vue-property-decorator';

import Loading from '@/components/Loading/Loading.vue';

// import CreditsIcon from '@/assets/icons/Credits.vue';

import { Doubt } from '@/globalInterfaces/Doubts';

import LightBoxImage from '@/mixins/LightBoxImage';
import CreditsConsumer from '@/mixins/CreditsConsumer';

import { replaceText } from '@/share/Util/Text';

@Component({
  mixins: [LightBoxImage],
  components: {
    Loading
    // CreditsIcon,
  }
})
export default class ReplyContent extends Mixins(CreditsConsumer) {
  @Prop({ required: true }) reply!: Doubt;
  @Prop({ default: 'Resposta' }) title!: string;
  @Prop({ default: false }) showAnswer!: boolean;
  @Prop({ default: false }) isLoading!: boolean;

  get isMovie() {
    return this.$store.getters.isMovie;
  }

  get replyUserID() {
    return this.reply?.userID;
  }

  get userID() {
    return this.$store.getters.profile.idstudent;
  }

  get IsMyReply() {
    return this.replyUserID === this.userID;
  }

  get showAnswerValue() {
    if (this.IsMyReply) return true;
    return this.showAnswer;
  }

  get content() {
    if (!this.showAnswer) return replaceText(this.reply.content || this.reply.contentfix);

    return this.reply.content || this.reply.contentfix;
  }

  get showButtonAnswer() {
    if (this.IsMyReply) return false;
    return !this.showAnswer;
  }

  handleClickShowAnswer() {
    this.$emit('onShowAnswer');
  }
}
