export const optimizeImageLoadingInAlternative = (text: string) => {
  const newContentAnswer = text.replace('<img ', '<img loading="lazy" ');

  return newContentAnswer;
};

export const replaceText = (text: string, replace = 'Explicaê') => text
  .split(' ')
  .map(() => replace)
  .join(' ');
