




























import { Component, Vue, Prop } from 'vue-property-decorator';

import Thumb from '@/components/Thumb/index.vue';

import { Doubt } from '@/globalInterfaces/Doubts';

const USER_TYPE_NUMBER_MONITOR = 0;

@Component({
  components: {
    Thumb
  }
})
export default class ReplyHeader extends Vue {
  @Prop({ required: true }) reply!: Doubt;

  get userTypeName() {
    return this.reply.user_type === USER_TYPE_NUMBER_MONITOR ? 'Monitor' : 'Aluno';
  }
}
