













import { Component, Vue, Prop } from 'vue-property-decorator';

import ReplyHeader from '../ReplyHeader/index.vue';
import ReplyContent from '../ReplyContent/index.vue';

import { Doubt } from '@/globalInterfaces/Doubts';

@Component({
  components: {
    ReplyHeader,
    ReplyContent
  }
})
export default class ReplyDoubt extends Vue {
  @Prop({ required: true }) reply!: Doubt;
  @Prop({ default: 'Resposta' }) title!: string;
  @Prop({ default: false }) showAnswer!: boolean;
  @Prop({ default: false }) isLoading!: boolean;

  handleClickShowAnswer() {
    this.$emit('onShowAnswer');
  }
}
